import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from "react-router-dom";


function PatreonLogin() {
  const [searchParams] = useSearchParams();
  const { user } = useAuthenticator((context) => [context.user]);
  const code = searchParams.get('code');
  const navigate = useNavigate();

  useEffect(() => {
    if (code) {
      console.log(user.getSignInUserSession()?.getIdToken().getJwtToken());
      fetch('http://localhost:3000/interaction/patreon-redirect?code=' + code, {
        method: 'GET',
        headers: {
          'Authorization': `${user.getSignInUserSession()?.getIdToken().getJwtToken() || 'test'}`,
        },
      })
        .then((result) => {
          console.log(result.body);
        })
        .finally(() => {
          const refreshToken = user.getSignInUserSession()?.getRefreshToken();
          if (refreshToken) {
            user.refreshSession(refreshToken, async (err, data) => {
              console.log('refreshed data: ' + JSON.stringify(data, null, 2));
              navigate('/');
            });
          }
        });
    }
  });

  return (
    <>
      Connecting Patreon Account...
    </>
  );
}

export default PatreonLogin;