import { Card, CardContent } from '@mui/material';
import DownloadComponent from './DownloadComponent';


export default function InstallPage() {
    return (
        <>
            <Card sx={{ minWidth: 275, marginBottom: 2 }}>
                <CardContent>
                    <DownloadComponent />
                </CardContent>
            </Card>
            {/* <InstallComponent /> */}
        </>
    );
}
