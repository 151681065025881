import { Card, CardContent } from '@mui/material';

export default function HistoryPage() {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <p>Coming soon to Patreon subscribers</p>
        <p>Access all of your Sentient Sim history for each of your sims!</p>
      </CardContent>
    </Card>
  );
}
