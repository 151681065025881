import WidgetBot from '@widgetbot/react-embed';

const WidgetWithOverlay = () => {
  const handleClick = () => {
    // Handle click event and navigate to the desired link
    // eslint-disable-next-line no-useless-concat
    window.open('https://disco' + 'rd.gg/JKbuu' + 'cMKwP', '_blank');
  };

  return (
    <div className="widget-wrapper">
      <WidgetBot
        server="1098029759201542144"
        channel="1098054540181381222"
        height={800}
        width={400}
      />

      <div className="overlay" onClick={handleClick} />
    </div>
  );
};

export default WidgetWithOverlay;