import { AppBar, Box, Button, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function MenuBar() {
    const navigate = useNavigate()

    const onDiscordClick = () => {
        // eslint-disable-next-line no-useless-concat
        window.open('https://disco' + 'rd.gg/JKbuu' + 'cMKwP', '_blank');
    }

    const onPatreonClick = () => {
        window.open('https://www.patreon.com/c/SentientSims', '_blank');
    }

    return (
        <Box sx={{ flexGrow: 1, marginBottom: 2 }}>
            <AppBar position="static" color='transparent' sx={{ backgroundColor: '#313339' }}>
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <div>
                        <Button color="secondary" onClick={() => navigate('/')} sx={{ margin: '2px' }}>
                            HOME
                        </Button>
                        <Button color="secondary" onClick={() => navigate('/install')} sx={{ margin: '2px' }}>
                            Install
                        </Button>
                        <a href="https://github.com/guspuffygit/sentient-sims-app/wiki"
                           target="_blank"
                           rel="noopener noreferrer"
                           style={{ textDecoration: 'none' }}
                        >
                            <Button color="secondary" sx={{ margin: '2px' }}>
                                Wiki
                            </Button>
                        </a>
                    </div>
                    <div>
                        <Button 
                            style={{
                                maxWidth: '40px',
                                maxHeight: '40px',
                                minWidth: '40px',
                                minHeight: '40px',
                            }}
                            sx={{marginRight: 1 }}
                            onClick={onDiscordClick}
                        >
                            <img src='/discord.svg' width={25} height={25} alt='Discord Link'/>
                        </Button>
                        <Button 
                            style={{
                                maxWidth: '40px',
                                maxHeight: '40px',
                                minWidth: '40px',
                                minHeight: '40px',
                            }}
                            onClick={onPatreonClick}
                        >
                            <img src='/patreon.svg' width={25} height={25} alt='Patreon Link'/>
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default MenuBar;