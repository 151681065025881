import { Card, CardContent, Divider, Typography } from "@mui/material";


export default function HowToPlayPage() {
  return (
    <Card sx={{ minWidth: 275, marginBottom: 2 }}>
      <CardContent>
        <Typography variant='h4' sx={{marginBottom: 2}}>How to Play</Typography>
        <Divider sx={{marginTop: 1, marginBottom: 3}}/>
      </CardContent>
    </Card>
  );
}