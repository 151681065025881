import linuxImage from './linux.png';
import '@fontsource/roboto/400.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import CssBaseline from '@mui/material/CssBaseline';

import './App.css';
import '@aws-amplify/ui-react/styles.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PatreonLogin from './PatreonLogin';
import { Authenticator } from '@aws-amplify/ui-react';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import InstallPage from './InstallPage';
import HistoryPage from './HistoryPage';
import HomePage from './HomePage';
import LoginPage from './LoginPage';
import HowToPlayPage from './HowToPlayPage';

const urls = new Map<string, string>();
urls.set('localhost', 'http://localhost:25149');
urls.set('www.sentientsimulations.com', 'https://www.sentientsimulations.com');
const updatedAwsConfig = {
  ...awsExports,
  oauth: {
    ...awsExports.oauth,
    redirectSignIn: urls.get(window.location.hostname),
    redirectSignOut: urls.get(window.location.hostname),
  }
};
Amplify.configure(updatedAwsConfig);

const router = createBrowserRouter([
  {
    path: '/',
    element: <App/>,
    children: [
      {
        path: '/',
        element: <HomePage/>,
      },
      {
        path: '/history',
        element: <HistoryPage/>,
      },
      {
        path: '/install',
        element: <InstallPage/>,
      },
      {
        path: '/login',
        element: <LoginPage/>,
      },
      {
        path: '/how-to-play',
        element: <HowToPlayPage/>,
      },
      {
        path: 'patreon-redirect',
        element: <PatreonLogin/>,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <div style={{
    backgroundImage: `url(${linuxImage})`,
    height: '100vh',
    backgroundRepeat: 'repeat',
  }}>
  <ThemeProvider theme={theme}>
      <CssBaseline/>
      <React.StrictMode>
        <Authenticator.Provider>
          <RouterProvider router={router}/>
        </Authenticator.Provider>
      </React.StrictMode>
    </ThemeProvider>
  </div>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
