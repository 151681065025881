import WelcomeMessageComponent from './WelcomeMessageComponent';
import FAQComponent from './FAQComponent';
import { ThankYouCardPatreon } from './ThankYouCard';

export default function HomePage() {
  return (
    <>
      <WelcomeMessageComponent />
      <FAQComponent />
      <ThankYouCardPatreon />
    </>
  )
}