import { Divider, Grid, Link, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

type DownloadLinks = {
  baseLink: string;
  intelMac: string;
  armMac: string;
  linux: string;
  windows: string;
}

export default function DownloadComponent() {
  const [links, setLinks] = useState<DownloadLinks | undefined>();

  async function getLatestDownloadLinks() {
    const response = await fetch('https://yrpkfzs65bbo7c66pb33v7wmuq0fxrvs.lambda-url.us-east-1.on.aws', {
      headers: {
        Accept: 'application/json',
      },
    });
    const versionBlob = await response.json();
    const version = versionBlob.version;
    const baseLink = `https://github.com/guspuffygit/sentient-sims-app/releases/download/v${version}`;
    const downloadLinks: DownloadLinks = {
      baseLink: baseLink,
      intelMac: `SentientSims-${version}.dmg`,
      armMac: `SentientSims-${version}-arm64.dmg`,
      linux: `SentientSims-${version}.AppImage`,
      windows: `SentientSims-Setup-${version}.exe`,
    };
    setLinks(downloadLinks);
  }

  useEffect(() => {
    getLatestDownloadLinks();
  }, []);

  if (!links) {
    return (
      <>
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      </>
    );
  }

  const rows = [
    { id: 1, image: '/apple.png', name: `Intel Mac - ${links.intelMac}`, url: `${links.baseLink}/${links.intelMac}` },
    { id: 2, image: '/apple.png', name: `Arm Mac - ${links.armMac}`, url: `${links.baseLink}/${links.armMac}` },
    { id: 3, image: '/windows.png', name: `Windows - ${links.windows}`, url: `${links.baseLink}/${links.windows}` },
    { id: 4, image: '/linux.png', name: `Linux - ${links.linux}`, url: `${links.baseLink}/${links.linux}` },
  ];

  return (
    <div>
      <Typography variant="h5" align="center">Download Sentient Sims</Typography>
      <Divider sx={{marginTop: 2, marginBottom: 2}}/>
      {rows.map((row) => (
        <Grid container spacing={2} key={row.id} direction={"row"} alignItems="center" justifyContent={"center"}>
          <Grid item xs={2}>
            <img src={row.image} width={"50"} alt={row.name} />
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h6">
              <Link href={row.url} download>
                {row.name}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};