import { useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import WidgetWithOverlay from './WidgetWithOverlay';
import MenuBar from './MenuBar';

function App() {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Container maxWidth="lg" className={'root'}>
        <Grid container spacing={3}>
          <Grid item xs={8} ref={containerRef}>
            <MenuBar />
            <Outlet />
          </Grid>
          <Grid item xs={4}>
            <WidgetWithOverlay />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default App;
