import { Card, CardContent, Typography } from '@mui/material';

export default function FAQComponent() {
  return (
    <>
      <Card sx={{ minWidth: 275, wordWrap: 'break-word', marginBottom: 2 }}>
        <CardContent sx={{ wordWrap: 'break-word' }}>
          <Typography variant="body1" align="center" sx={{marginBottom: 2}}>As you play The Sims 4, interactions between Sims will be narrated by the AI as they happen.</Typography>
          <img src="/do_something_result.png" alt="Do Something Interaction" className="center"/>
          <Typography variant="body1" align="center" sx={{marginTop: 2, marginBottom: 1}}>The story is influenced by each Sims' wants, needs, relationships, moods, personality traits, and the environment they are in. You have the power to edit the personality of each Sim, giving them their own flavor so that they will react and act how you want them to!</Typography>
        </CardContent>
      </Card>
    </>
  )
}
