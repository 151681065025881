import { Card, CardContent, Typography } from '@mui/material';

export default function WelcomeMessageComponent() {
    return (
        <Card sx={{ minWidth: 275, marginBottom: 2 }}>
            <CardContent>
                <Typography variant="h4" align="center" >Sentient Sims</Typography>
                <Typography variant='body1' align="center">The AI mod for The Sims 4</Typography>
            </CardContent>
        </Card>
    )
}